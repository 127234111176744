// Get the element you want to add the class to
const element = document.getElementById("page-header");
const pageSpacer = document.getElementById("page-spacer");

const observer = new IntersectionObserver((entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
        element.classList.remove("header-block--sticky");
    } else {
        element.classList.add("header-block--sticky");
    }
});

observer.observe(pageSpacer);
