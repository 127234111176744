// JavaScript Document

// Scripts written by Jordan Bearce @ Weblinx, Inc.

import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";

const HERO = document.querySelector(".swiper-container--hero");

const CLIENTS = document.querySelector(".swiper-container--clients");

// init HERO swiper
if (HERO && HERO.querySelectorAll(".swiper-slide").length > 1) {
    new Swiper (HERO, {
        modules: [Autoplay, EffectFade, Navigation, Pagination],
        autoplay: {
            delay: 7500,
        },
        effect: "fade",
        loop: true,
        navigation: {
            nextEl: HERO.querySelector(".swiper-button--next"),
            prevEl: HERO.querySelector(".swiper-button--prev"),
        },
        pagination: {
            el: HERO.querySelector(".swiper-pagination"),
            clickable: true,
        },
        speed: 150,
    });
}

// init CLIENTS swiper
if (CLIENTS && CLIENTS.querySelectorAll(".swiper-slide").length > 1) {
    new Swiper (CLIENTS, {
        modules: [Autoplay, Navigation],
        autoplay: {
            delay: 7500,
        },
        loop: true,
        navigation: {
            nextEl: CLIENTS.querySelector(".swiper-button--next"),
            prevEl: CLIENTS.querySelector(".swiper-button--prev"),
        },
        speed: 150,
        slidesPerView: 1,
        spaceBetween: 32,
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 60,
            }
        },
    });
}
