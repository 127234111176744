// JavaScript Document

// Scripts written by Jordan Bearce @ Weblinx, Inc.

document.addEventListener("DOMContentLoaded", function () {
    const COPYRIGHT_YEAR = document.querySelector("#copyright_year");
    if (COPYRIGHT_YEAR) {
        const theYear = new Date().getFullYear();
        COPYRIGHT_YEAR.textContent = theYear;

    }
});
